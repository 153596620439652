import axios from "@/config/httpConfig";

// 查询伙伴云产品系列接口
export function getProductSort(data) {
    return axios.post("/partnersort/getProductSort", data);
}

// 保存新增产品系列接口
export function saveProductSort(data) {
    return axios.post("/partnersort/saveProductSort", data);
}

// 更新产品系列接口
export function updateProductSort(data) {
    return axios.post("/partnersort/updateProductSort", data);
}

// 删除产品系列接口
export function deleteProductSort(data) {
    return axios.post("/partnersort/deleteProductSort", data);
}

// 查询父级产品系列接口
export function queryFatherProductSort(data) {
    return axios.post("/partnersort/queryFatherProductSort", data);
}
