<template>
  <div class="productSetBox">
    <!-- 头部文字说明 -->
    <div class="captions">
      <!-- 说明：可以为产品系列设置层级，在系统内部分功能添加产品时，可以按层级筛选查看。最多支持设置10级。 -->
      {{ $t("label.partnerCloud.productSet.instructions") }}
    </div>
    <!-- 树状产品系列无数据时展示 新建添加按钮 -->
    <div class="addNew" @click="addNewTree">
      {{ $t("label.partnerCloud.newProductSeries") }}
      <!-- <i class="el-icon-plus"></i> -->
    </div>
    <!-- 主体树状产品系列 -->
    <div class="product-tree">
      <el-tree
        :data="productData"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        @node-click="handleNodeClick"
        :render-content="renderContent"
        :props="defaultProps"
      >
      </el-tree>
    </div>
    <!-- 编辑   新建   删除  弹窗 -->
    <div class="newProduct">
      <!-- 初始无数据 新建 弹窗 -->
      <!-- 新建选项  label.partnerCloud.productSet.addNew  -->
      <el-dialog
        :visible.sync="initialAddNewIsShow"
        :title="$t('label.partnerCloud.productSet.addNew')"
        :before-close="initialAddNewHandleClose"
        :close-on-click-modal="false"
        custom-class="addNewDialog"
      >
        <div class="dialog-body">
          <el-form
            ref="initialAddNewData"
            :model="initialAddNewData"
            class="demo-ruleForm"
          >
            <!-- 选项名称  label.partnerCloud.productSet.optionName   -->
            <el-form-item
              :label="$t('label.partnerCloud.productSet.optionName')"
            >
              <el-input v-model="initialAddNewData.optionNameThree"></el-input>
            </el-form-item>
            <!-- <el-form-item label="父级选项">
              <el-input v-model="initialAddNewData.parentOptionThree"></el-input>
            </el-form-item> -->
            <el-form-item
              :label="$t('label.partnerCloud.productSet.parentOptions')"
            >
              <el-select
                v-model="initialAddNewData.parentOptionThree"
                placeholder=""
                clearable
              >
                <el-option
                  v-for="item in fatherProductInitialAddNew"
                  :key="item.id"
                  :label="item.codevalue"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取消    保存  -->
          <el-button @click="initialAddNewCancelBtn">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <el-button type="primary" @click="initialAddNewSaveBtn">{{
            $t("component.telerecord.button.save")
          }}</el-button>
        </span>
      </el-dialog>

      <!-- 编辑  弹窗 -->
      <el-dialog
        :visible.sync="editorIsShow"
        :title="$t('label.partnerCloud.productSet.editor')"
        :before-close="editorHandleClose"
        :close-on-click-modal="false"
        custom-class="editorDialog"
      >
        <div class="dialog-body">
          <el-form ref="editorData" :model="editorData" class="demo-ruleForm">
            <!-- 选项名称  label.partnerCloud.productSet.optionName    父级选项 label.partnerCloud.productSet.parentOptions -->
            <el-form-item
              :label="$t('label.partnerCloud.productSet.optionName')"
            >
              <el-input v-model="editorData.optionName"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('label.partnerCloud.productSet.parentOptions')"
            >
              <el-select
                v-model="editorData.parentOption"
                placeholder=""
                clearable
              >
                <el-option
                  v-for="item in fatherProductEditor"
                  :key="item.id"
                  :label="item.codevalue"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取消    保存  -->
          <el-button @click="editorCancelBtn">
            {{ $t("label.emailsync.button.cancel") }}
          </el-button>
          <el-button type="primary" @click="editorSaveBtn">
            {{ $t("component.telerecord.button.save") }}
          </el-button>
        </span>
      </el-dialog>

      <!-- 新建 添加子级 弹窗 -->
      <!-- 新建选项  label.partnerCloud.productSet.addNew  -->
      <el-dialog
        :visible.sync="addNewIsShow"
        :title="$t('label.partnerCloud.productSet.addNew')"
        :before-close="addNewHandleClose"
        :close-on-click-modal="false"
        custom-class="addNewDialog"
      >
        <div class="dialog-body">
          <el-form ref="addNewData" :model="addNewData" class="demo-ruleForm">
            <!-- 选项名称  label.partnerCloud.productSet.optionName    父级选项 label.partnerCloud.productSet.parentOptions -->
            <el-form-item
              :label="$t('label.partnerCloud.productSet.optionName')"
            >
              <el-input v-model="addNewData.optionNameTwo"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('label.partnerCloud.productSet.parentOptions')"
            >
              <el-select
                v-model="addNewData.parentOptionTwo"
                placeholder=""
                clearable
              >
                <el-option
                  v-for="item in fatherProductAdd"
                  :key="item.id"
                  :label="item.codevalue"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取消    保存  -->
          <el-button @click="addNewCancelBtn">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <el-button type="primary" @click="addNewSaveBtn">{{
            $t("component.telerecord.button.save")
          }}</el-button>
        </span>
      </el-dialog>

      <!-- 删除  弹窗 -->
      <!-- 删除选项 label.partnerCloud.productSet.del     确认删除该条产品  label.partnerCloud.productSet.delIsTrue -->
      <el-dialog
        :visible.sync="deleteRuleTo"
        :title="$t('label.partnerCloud.productSet.del')"
        :before-close="handleClose"
        :close-on-click-modal="false"
        custom-class="deleteDialog"
      >
        <div class="dialog-body">
          <div>{{ $t("label.partnerCloud.productSet.delIsTrue") }}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取消    确认  -->
          <el-button @click="deleteRuleCancel">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <el-button type="primary" @click="deleteRuleConfirm">{{
            $t("label.emailtocloudcc.button.sure")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as productSetApi from "./api";
export default {
  name: "productSetting",
  data() {
    const productData = [];
    return {
      id:100,
      defaultProps: {
        children: "childList",
        label: "codevalue",
      },
      productData: JSON.parse(JSON.stringify(productData)), //所有产品系列数据
      isact: "", //当前hover的节点
      initialAddNewIsShow: false, //无数据时 新建添加产品
      fatherProductInitialAddNew: [], //无数据时新建添加产品 获取父级产品的数据
      initialAddNewData: {
        optionNameThree: "",
        parentOptionThree: "",
      },
      editorData: {
        optionName: "",
        parentOption: "",
      },
      editorIsShow: false, //编辑 弹框的显示和隐藏控制
      editorTempTree: "", //编辑的对象
      editorTempTreeNode: "", // 编辑的对象的Node 里面有层级
      fatherProductEditor: [], //编辑对象时 获取父级产品的数据
      addNewIsShow: false, //新建 弹框的显示和隐藏控制
      addNewTempTree: "", //新建的对象
      addNewTempTreeNode: "", //新建的对的Node
      fatherProductAdd: [], //新建对象时 获取父级产品的数据
      addNewData: {
        optionNameTwo: "",
        parentOptionTwo: "",
      },
      deleteRuleTo: false, //删除 弹框的显示和隐藏控制
      deleteTempTreeData: "", //编辑的对象的data
      deleteTempTreeNode: "", //编辑的对象的node
    };
  },
  mounted() {
    this.getProductSort();
  },
  methods: {
    handleNodeClick() {},
    // 初始无数据 新建 叉号 弹框
    initialAddNewHandleClose() {
      this.initialAddNewIsShow = false;
    },
    // 初始无数据 新建 取消 弹框
    initialAddNewCancelBtn() {
      this.initialAddNewIsShow = false;
    },

    // 编辑 叉号 弹框
    editorHandleClose() {
      this.editorIsShow = false;
    },
    // 编辑 取消 弹框
    editorCancelBtn() {
      this.editorIsShow = false;
    },

    // 新建 添加子级 叉号 弹框
    addNewHandleClose() {
      this.addNewIsShow = false;
    },
    // 新建 添加子级 取消 弹框
    addNewCancelBtn() {
      this.addNewIsShow = false;
    },

    // 删除 叉号 弹框
    handleClose() {
      this.deleteRuleTo = false;
    },
    // 删除 取消 弹框
    deleteRuleCancel() {
      this.deleteRuleTo = false;
    },

    // 悬浮 tree  出现的 操作按钮
    // 编辑
    renderContent(h, { node, data, store }) {
      return (
        <span
          class="custom-tree-node"
          on-mouseenter={() => this.mouseenteract(data)}
          on-mouseleave={() => this.mouseleaveact(data)}
        >
          <span>{node.label}</span>
          {this.isact === data ? (
            <span class="operationBtn">
              <el-button
                size="mini"
                type="text"
                on-click={() => this.editorTree(node, data, store)}
              >
                {this.$i18n.t("label.emailtocloudcc.edit")}
              </el-button>
              <el-button
                size="mini"
                type="text"
                on-click={() => this.removeTree(node, data)}
              >
                {this.$i18n.t("label.emailtocloudcc.delete")}
              </el-button>
              <el-button
                size="mini"
                type="text"
                on-click={() => this.appendTree(node, data)}
              >
                {this.$i18n.t("label.partnerCloud.productSet.addChildren")}
              </el-button>
            </span>
          ) : (
            <span></span>
          )}
        </span>
      );
    },
    //获取鼠标进入节点的数据
    mouseenteract(da) {
      this.isact = da;
    },
    mouseleaveact() {
      this.isact = "";
    },

    // 初始进入页面 获取全部产品系列接口
    getProductSort() {
      productSetApi.getProductSort().then((res) => {
        this.productData = res.data;
      });
    },

    // 产品数无数据时  点击新建添加产品
    addNewTree() {
      this.initialAddNewIsShow = true;
      // 获取要添加产品的父级系列
      let op = {
        productsortid: "",
      };
      productSetApi.queryFatherProductSort(op).then((res) => {
        this.fatherProductInitialAddNew = res.data;
      });
    },
    // 产品数无数据时 保存添加产品
    // 名称不能为空   label.ccchat.error.nullname
    initialAddNewSaveBtn() {
      this.initialAddNewIsShow = false;
      if (this.initialAddNewData.optionNameThree == "") {
        this.$message.warning(this.$i18n.t("label.ccchat.error.nullname"));
      } else {
        let op = {
          fatherid: this.initialAddNewData.parentOptionThree,
          levelNum: 1,
          codevalue: this.initialAddNewData.optionNameThree,
        };
        productSetApi.saveProductSort(op).then(() => {
          // 重新调取查询接口 刷新数据
          this.getProductSort();
        });
      }
      this.initialAddNewData = [];
    },

    // 编辑对应的 节点树
    editorTree(node, data) {
      this.editorIsShow = true;
      // const parent = node.parent;
      // const children = parent.data.children || parent.data;
      // // 遍历找到对应id 的 对象
      // const index = children.findIndex((d) => d.id === data.id);
      // children.splice(index, 1, this.editorTempTree);

      // 编辑的对象的数据
      this.editorTempTree = data;
      // 编辑的对象的Node 里面有层级
      this.editorTempTreeNode = node;
      // 回显选项名称和父级
      this.$set(this.editorData, "optionName", data.codevalue);
      this.$set(
        this.editorData,
        "parentOption",
        node.data.fatherid === "null" ? "" : node.data.fatherid
      );
      // 获取要添加产品的父级系列
      let op = {
        productsortid: data.id,
      };
      productSetApi.queryFatherProductSort(op).then((res) => {
        this.fatherProductEditor = res.data;
      });
    },

    // 新建添加 新的节点树
    appendTree(node, data) {
      this.addNewIsShow = true;
      //新建的对的数据
      this.addNewTempTree = data;
      //新建的对的Node
      this.addNewTempTreeNode = node;
      // 回显父级
      this.$set(this.addNewData, "parentOptionTwo", data.id);
      // this.addNewData.parentOptionTwo = data.id;
      // 获取要添加产品的父级系列
      let op = {
        productsortid: "",
      };
      productSetApi.queryFatherProductSort(op).then((res) => {
        this.fatherProductAdd = res.data;
      });
    },
    // 删除对应的节点树
    removeTree(node, data) {
      this.deleteRuleTo = true;
      this.deleteTempTreeNode = node;
      this.deleteTempTreeData = data;
    },

    // 编辑完成后 保存 弹框   调取更新产品系列的接口
    editorSaveBtn() {
      this.editorIsShow = false;
      if (this.editorData.optionName == "") {
        this.$message.warning(this.$i18n.t("label.ccchat.error.nullname"));
        return;
      } else {
        let op = {
          fatherid: this.editorData.parentOption,
          levelnum: this.editorTempTreeNode.level,
          codevalue: this.editorData.optionName,
          codeid: this.editorTempTree.id,
        };
        productSetApi.updateProductSort(op).then((res) => {
          if (res.returnCode == "1") {
            // 重新调取查询接口 刷新数据
            this.getProductSort();
          } else {
            this.$message.warning(res.returnInfo);
          }
        });
      }
      this.editorData = [];
    },
    // 新建完成后 保存 弹框  调取新增产品系列的接口
    addNewSaveBtn() {
      this.addNewIsShow = false;
      if (this.addNewData.optionNameTwo == "") {
        this.$message.warning(this.$i18n.t("label.ccchat.error.nullname"));
        return;
      } else {
        // const newChild = { id: id++, label: "", children: [] };
        // if (!this.addNewTempTree.children) {
        //   this.$set(this.addNewTempTree, "children", []);
        // }
        // newChild.label = this.addNewData.optionNameTwo;
        // this.addNewTempTree.children.push(newChild);

        let op = {
          fatherid: this.addNewData.parentOptionTwo,
          levelNum: this.addNewTempTreeNode.level + 1,
          codevalue: this.addNewData.optionNameTwo,
        };
        productSetApi.saveProductSort(op).then((res) => {
          if (res.returnCode == "1") {
            // 重新调取查询接口 刷新数据
            this.getProductSort();
          } else {
            this.$message.warning(res.returnInfo);
          }
        });
        // 
      }
      this.addNewData = [];
    },
    // 删除 确认 弹框
    deleteRuleConfirm() {
      this.deleteRuleTo = false;
      // const parent = this.deleteTempTreeNode.parent;
      // // 
      // const children = parent.data.children || parent.data;
      // const index = children.findIndex((d) => d.id === this.deleteTempTreeData.id);
      // children.splice(index, 1);

      let op = {
        productSortId: this.deleteTempTreeData.id,
      };
      productSetApi.deleteProductSort(op).then(() => {
        // 重新调取查询接口 刷新数据
        this.getProductSort();
      });
    },
  },
};
</script>
<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-right: 8px;
}
.operationBtn {
  margin-left: 100px;
}
</style>
<style lang="scss" scoped>
.productSetBox {
  width: 100%;
  padding: 20px 0 0 30px;
  // 文字说明
  .captions {
    width: 100%;
    // border: solid 1px;
    padding: 0 0 0 5px;
  }
  .addNew {
    // width: 95px;
    margin-top: 20px;
    cursor: pointer;
    color: #3a8ee6;
    // text-align: center;
    .el-icon-plus {
      font-size: 25px;
    }
  }
  // 产品树状系列
  .product-tree {
    width: 100%;
    // border: solid 1px;
    margin: 30px 0 0 0;
  }
  //新建 编辑 添加 弹窗
  .newProduct {
    width: 100%;
    // 删除弹框样式
    ::v-deep .deleteDialog {
      width: 30%;
    }
    // 新建弹框样式
    ::v-deep .addNewDialog {
      width: 50%;
    }
    // 编辑弹框样式
    ::v-deep .editorDialog {
      width: 50%;
    }

    // form表单样式
    ::v-deep .demo-ruleForm {
      width: 66%;
      margin: auto;
      // 代码集/单选框样式
      .codeSetClass,
      .radioClass {
        margin-bottom: 8px !important;
      }
      // 单选框
      .radioClass {
        .el-radio-group {
          margin-left: -86px;
          .el-radio:first-of-type {
            margin-right: 60px;
          }
        }
      }
      .el-form-item {
        img {
          display: inline-block;
          margin-left: 10px;
        }
        margin-bottom: 20px;
        // label
        .el-form-item__label {
          font-size: 14px;
          text-align: right;
          font-weight: bold;
          color: #080707;
          // margin-left: 20px;
        }
        // content
        .el-form-item__content {
          margin-left: 130px !important;
          .el-textarea {
            width: 319px;
          }
          .el-input {
            width: 319px;
            height: 30px;
            .el-input__inner {
              height: 30px;
              line-height: 30px;
            }
            .el-input__suffix {
              .el-input__icon {
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
</style>